const actions = {
  SET_STATE: 'capitalAccount/SET_STATE',

  ADD: 'capitalAccount/ADD',
  UPDATE: 'capitalAccount/UPDATE',
  DELETE: 'capitalAccount/DELETE',

  GET_LIST: 'capitalAccount/GET_LIST',
  RESET_LIST: 'capitalAccount/RESET_LIST',

  GET_EDITABLE: 'capitalAccount/GET_EDITABLE',
  RESET_EDITABLE: 'capitalAccount/RESET_EDITABLE',

  GET_DETAILS: 'capitalAccount/GET_DETAILS',
  RESET_DETAILS: 'capitalAccount/RESET_DETAILS',

  GET_CURRENT_CAPITAL: 'capitalAccount/GET_CURRENT_CAPITAL',
  RESET_CURRENT_CAPITAL: 'capitalAccount/RESET_CURRENT_CAPITAL',

  RESET_SAVING_STATUS: 'capitalAccount/RESET_SAVING_STATUS',
}

export default actions
