import { combineReducers } from 'redux'

import mainUnit from './main-unit/reducers'
import unit from './unit/reducers'
import productGroup from './product-group/reducers'
import productSubGroup from './product-sub-group/reducers'
import productCategory from './product-category/reducers'
import productSubCategory from './product-sub-category/reducers'
import productBrand from './product-brand/reducers'
import productModel from './product-model/reducers'
import product from './product/reducers'
import packageGroup from './package-group/reducers'
import productPackage from './package/reducers'

export default combineReducers({
  mainUnit,
  unit,
  productGroup,
  productSubGroup,
  productCategory,
  productSubCategory,
  productBrand,
  productModel,
  product,
  packageGroup,
  productPackage,
})
