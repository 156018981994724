import { all } from 'redux-saga/effects'

import aag from './aag/sagas'
import account from './account/sagas'
import bankAccount from './bank-account/sagas'
import fixedAsset from './fixed-asset/sagas'
import capitalAccount from './capital-account/sagas'

export default function* rootSaga() {
  yield all([aag(), account(), bankAccount(), fixedAsset(), capitalAccount()])
}
