import { combineReducers } from 'redux'

import pi from './purchase-invoices/purchase-invoice/reducers'
import pri from './purchase-invoices/purchase-return-invoice/reducers'
import si from './sale-invoices/sale-invoice/reducers'
import sri from './sale-invoices/sale-return-invoice/reducers'

export default combineReducers({
  pi,
  pri,
  si,
  sri,
})
