import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import moment from 'moment'

import styles from './style.module.scss'

const mapStateToProps = ({ session }) => {
  const { currentSessionInfo } = session.sessionInfo

  return {
    sessionName: currentSessionInfo.display_name,
    sessionStartDate: currentSessionInfo.start_date,
    sessionEndDate: currentSessionInfo.end_date,
    sessionCurrentDate: currentSessionInfo.session_date?.current_date,
    isSessionCurrentDateLocked: currentSessionInfo.session_date?.is_locked || true,
  }
}

const SessionInfo = ({ sessionName, sessionStartDate, sessionEndDate, sessionCurrentDate }) => {
  const menu = (
    <Menu selectable={false} className="width-300">
      <Menu.ItemGroup title="Current Session">
        <div className="d-flex justify-content-between p-1">
          <span>Name</span>
          <strong>{sessionName}</strong>
        </div>
        <div className="d-flex justify-content-between p-1">
          <span>Start Date</span>
          <strong>{moment(sessionStartDate).format('DD MMMM YYYY')}</strong>
        </div>
        <div className="d-flex justify-content-between p-1">
          <span>End Date</span>
          <strong>{moment(sessionEndDate).format('DD MMMM YYYY')}</strong>
        </div>
      </Menu.ItemGroup>

      <Menu.Divider />

      <Menu.ItemGroup title="Current Date">
        <div className="d-flex justify-content-between p-1">
          <strong>{moment(sessionCurrentDate).format('DD MMMM YYYY')}</strong>
          {/* <strong>{isSessionCurrentDateLocked ? <span className="text-success">LOCKED</span> : <span className="text-danger">UNLOCKED</span>}</strong> */}
        </div>
      </Menu.ItemGroup>

      <Menu.Divider />

      {
        // todo
        // view previous session info
      }
      <Menu.ItemGroup title="Login Session">
        <div className="d-flex justify-content-between p-1">
          <span>Name</span>
          <strong>July-2019 to June-2020</strong>
        </div>
        <div className="d-flex justify-content-between p-1">
          <span className="text-danger">
            You can view reports for this session. Any kind of posting are not allowed into this
            session.
          </span>
        </div>
      </Menu.ItemGroup>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <i className={`${styles.icon} fe fe-clock`} />
        <span className="d-none d-lg-inline">Session Info</span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(SessionInfo)
