import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import FallbackSpinner from 'components/custom/Spinners/FallbackSpinner'

const routes = [
  // ******************** DASHBOARDS ********************
  {
    path: '/dashboard/alpha',
    Component: lazy(() => import('pages/dashboard/alpha')),
    exact: true,
  },

  // ******************** REGISTRATION ********************
  // =============== Account Registration ===============
  {
    path: '/registration/account/account-access-group',
    Component: lazy(() => import('pages/registration/account/aag')),
    exact: true,
  },
  {
    path: '/registration/account/account-access-group-list',
    Component: lazy(() => import('pages/registration/account/aag-list')),
    exact: true,
  },
  {
    path: '/registration/account/account',
    Component: lazy(() => import('pages/registration/account/account')),
    exact: true,
  },
  {
    path: '/registration/account/account-list',
    Component: lazy(() => import('pages/registration/account/account-list')),
    exact: true,
  },
  {
    path: '/registration/account/account-ledger-list',
    Component: lazy(() => import('pages/registration/account/account-ledger-list')),
    exact: true,
  },
  {
    path: '/registration/account/bank-account',
    Component: lazy(() => import('pages/registration/account/bank-account')),
    exact: true,
  },
  {
    path: '/registration/account/bank-account-list',
    Component: lazy(() => import('pages/registration/account/bank-account-list')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset',
    Component: lazy(() => import('pages/registration/account/fixed-asset')),
    exact: true,
  },
  {
    path: '/registration/account/fixed-asset-list',
    Component: lazy(() => import('pages/registration/account/fixed-asset-list')),
    exact: true,
  },
  {
    path: '/registration/account/capital',
    Component: lazy(() => import('pages/registration/account/capital-account')),
    exact: true,
  },
  {
    path: '/registration/account/capital-list',
    Component: lazy(() => import('pages/registration/account/capital-account-list')),
    exact: true,
  },

  // =============== Chart Of Accounts ===============
  {
    path: '/registration/coa/chart-of-accounts',
    Component: lazy(() => import('pages/registration/coa/chart-of-accounts')),
    exact: true,
  },
  {
    path: '/registration/coa/group',
    Component: lazy(() => import('pages/registration/coa/coa-group')),
    exact: true,
  },
  {
    path: '/registration/coa/group-list',
    Component: lazy(() => import('pages/registration/coa/coa-group-list')),
    exact: true,
  },
  {
    path: '/registration/coa/subgroup',
    Component: lazy(() => import('pages/registration/coa/coa-subgroup')),
    exact: true,
  },
  {
    path: '/registration/coa/subgroup-list',
    Component: lazy(() => import('pages/registration/coa/coa-subgroup-list')),
    exact: true,
  },

  // =============== Party Registration ===============
  {
    path: '/registration/party/region',
    Component: lazy(() => import('pages/registration/party/region')),
    exact: true,
  },
  {
    path: '/registration/party/region-list',
    Component: lazy(() => import('pages/registration/party/region-list')),
    exact: true,
  },
  {
    path: '/registration/party/area',
    Component: lazy(() => import('pages/registration/party/area')),
    exact: true,
  },
  {
    path: '/registration/party/area-list',
    Component: lazy(() => import('pages/registration/party/area-list')),
    exact: true,
  },
  {
    path: '/registration/party/sector',
    Component: lazy(() => import('pages/registration/party/sector')),
    exact: true,
  },
  {
    path: '/registration/party/sector-list',
    Component: lazy(() => import('pages/registration/party/sector-list')),
    exact: true,
  },
  {
    path: '/registration/party/client',
    Component: lazy(() => import('pages/registration/party/client')),
    exact: true,
  },
  {
    path: '/registration/party/client-list',
    Component: lazy(() => import('pages/registration/party/client-list')),
    exact: true,
  },
  {
    path: '/registration/party/supplier',
    Component: lazy(() => import('pages/registration/party/supplier')),
    exact: true,
  },
  {
    path: '/registration/party/supplier-list',
    Component: lazy(() => import('pages/registration/party/supplier-list')),
    exact: true,
  },

  // =============== Product Registration ===============
  {
    path: '/registration/product/main-unit',
    Component: lazy(() => import('pages/registration/product/main-unit')),
    exact: true,
  },
  {
    path: '/registration/product/main-unit-list',
    Component: lazy(() => import('pages/registration/product/main-unit-list')),
    exact: true,
  },
  {
    path: '/registration/product/unit',
    Component: lazy(() => import('pages/registration/product/unit')),
    exact: true,
  },
  {
    path: '/registration/product/unit-list',
    Component: lazy(() => import('pages/registration/product/unit-list')),
    exact: true,
  },
  {
    path: '/registration/product/group',
    Component: lazy(() => import('pages/registration/product/product-group')),
    exact: true,
  },
  {
    path: '/registration/product/group-list',
    Component: lazy(() => import('pages/registration/product/product-group-list')),
    exact: true,
  },
  {
    path: '/registration/product/category',
    Component: lazy(() => import('pages/registration/product/product-category')),
    exact: true,
  },
  {
    path: '/registration/product/category-list',
    Component: lazy(() => import('pages/registration/product/product-category-list')),
    exact: true,
  },
  {
    path: '/registration/product/brand',
    Component: lazy(() => import('pages/registration/product/product-brand')),
    exact: true,
  },
  {
    path: '/registration/product/brand-list',
    Component: lazy(() => import('pages/registration/product/product-brand-list')),
    exact: true,
  },
  {
    path: '/registration/product/model',
    Component: lazy(() => import('pages/registration/product/product-model')),
    exact: true,
  },
  {
    path: '/registration/product/model-list',
    Component: lazy(() => import('pages/registration/product/product-model-list')),
    exact: true,
  },
  {
    path: '/registration/product/product',
    Component: lazy(() => import('pages/registration/product/product')),
    exact: true,
  },
  {
    path: '/registration/product/product-list',
    Component: lazy(() => import('pages/registration/product/product-list')),
    exact: true,
  },
  {
    path: '/registration/product/package-group',
    Component: lazy(() => import('pages/registration/product/package-group')),
    exact: true,
  },
  {
    path: '/registration/product/package-group-list',
    Component: lazy(() => import('pages/registration/product/package-group-list')),
    exact: true,
  },
  {
    path: '/registration/product/package',
    Component: lazy(() => import('pages/registration/product/package')),
    exact: true,
  },
  {
    path: '/registration/product/package-list',
    Component: lazy(() => import('pages/registration/product/package-list')),
    exact: true,
  },

  // =============== General Registration ===============
  {
    path: '/registration/general/warehouse',
    Component: lazy(() => import('pages/registration/general/warehouse')),
    exact: true,
  },
  {
    path: '/registration/general/warehouse-list',
    Component: lazy(() => import('pages/registration/general/warehouse-list')),
    exact: true,
  },
  {
    path: '/registration/general/service',
    Component: lazy(() => import('pages/registration/general/service')),
    exact: true,
  },
  {
    path: '/registration/general/service-list',
    Component: lazy(() => import('pages/registration/general/service-list')),
    exact: true,
  },

  // =============== Human Resources ===============
  {
    path: '/registration/human-resources/user',
    Component: lazy(() => import('pages/registration/human-resources/user')),
    exact: true,
  },
  {
    path: '/registration/human-resources/user-list',
    Component: lazy(() => import('pages/registration/human-resources/user-list')),
    exact: true,
  },
  {
    path: '/user/profile',
    Component: lazy(() => import('pages/registration/human-resources/user/profile')),
    exact: true,
  },
  {
    path: '/registration/human-resources/salary-user',
    Component: lazy(() => import('pages/registration/human-resources/salary-user')),
    exact: true,
  },
  {
    path: '/registration/human-resources/salary-user-list',
    Component: lazy(() => import('pages/registration/human-resources/salary-user-list')),
    exact: true,
  },

  // =============== System Preferences ===============
  {
    path: '/system/user-list',
    Component: lazy(() => import('pages/setting/user-list')),
    exact: true,
  },
  {
    path: '/system/shareholder-list',
    Component: lazy(() => import('pages/setting/shareholder-list')),
    exact: true,
  },

  // ========================= Cash Vouchers ========================
  {
    path: '/vouchers/cash-receipt-voucher',
    Component: lazy(() => import('pages/vouchers/cash-vouchers/receipt-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/cash-receipt-voucher-list',
    Component: lazy(() => import('pages/vouchers/cash-vouchers-list/receipt-voucher-list')),
    exact: true,
  },
  {
    path: '/vouchers/cash-payment-voucher',
    Component: lazy(() => import('pages/vouchers/cash-vouchers/payment-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/cash-payment-voucher-list',
    Component: lazy(() => import('pages/vouchers/cash-vouchers-list/payment-voucher-list')),
    exact: true,
  },

  // ========================= Bank Vouchers ========================
  {
    path: '/vouchers/bank-receipt-voucher',
    Component: lazy(() => import('pages/vouchers/bank-vouchers/receipt-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/bank-receipt-voucher-list',
    Component: lazy(() => import('pages/vouchers/bank-vouchers-list/receipt-voucher-list')),
    exact: true,
  },
  {
    path: '/vouchers/bank-payment-voucher',
    Component: lazy(() => import('pages/vouchers/bank-vouchers/payment-voucher')),
    exact: true,
  },
  {
    path: '/vouchers/bank-payment-voucher-list',
    Component: lazy(() => import('pages/vouchers/bank-vouchers-list/payment-voucher-list')),
    exact: true,
  },

  // ========================= Vouchers ========================
  {
    path: '/vouchers/jv',
    Component: lazy(() => import('pages/vouchers/journal-vouchers')),
    exact: true,
  },

  {
    path: '/vouchers/jv-list',
    Component: lazy(() => import('pages/vouchers/journal-vouchers-list')),
    exact: true,
  },

  // ========================= Salary Vouchers ========================
  {
    path: '/vouchers/salary-slip',
    Component: lazy(() => import('pages/vouchers/salary-vouchers/slip')),
    exact: true,
  },

  {
    path: '/vouchers/salary-slip-list',
    Component: lazy(() => import('pages/vouchers/salary-vouchers/slip-list')),
    exact: true,
  },

  // ========================= Purchase Invoices ========================
  {
    path: '/invoices/purchase-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-invoice-list')),
    exact: true,
  },
  {
    path: '/invoices/purchase-return-invoice',
    Component: lazy(() => import('pages/invoices/purchases/purchase-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/purchase-return-invoice-list',
    Component: lazy(() => import('pages/invoices/purchases/purchase-return-invoice-list')),
    exact: true,
  },

  // ========================= Sale Invoices ========================
  {
    path: '/invoices/sale-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-invoice-list')),
    exact: true,
  },

  {
    path: '/invoices/sale-return-invoice',
    Component: lazy(() => import('pages/invoices/sales/sale-return-invoice')),
    exact: true,
  },
  {
    path: '/invoices/sale-return-invoice-list',
    Component: lazy(() => import('pages/invoices/sales/sale-return-invoice-list')),
    exact: true,
  },

  // ******************** AUTH PAGES ********************
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/setup-password',
    Component: lazy(() => import('pages/auth/setup-password')),
    exact: true,
  },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('pages/auth/register')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/lockscreen',
  //   Component: lazy(() => import('pages/auth/lockscreen')),
  //   exact: true,
  // },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/error',
    Component: lazy(() => import('pages/auth/error')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={<FallbackSpinner />}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
