import actions from './actions'

const initialState = {
  id: '',
  employee_code: '',
  email: '',
  name: '',
  username: '',
  designation: '',
  cell_number: '',
  father_name: '',
  emergency_number: '',
  cnic: '',
  is_married: false,
  is_muslim: false,
  role: {},
  type: {},
  blood_group: {},
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  spinning: false,
  loading: false,
  savingStatus: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
