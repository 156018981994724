export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Home',
    },
    {
      title: 'Dashboards',
      key: 'dashboards',
      icon: 'fe fe-home',
      // roles: ['admin'], // set user roles with access to this route
      count: 1,
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
      ],
    },

    {
      category: true,
      title: 'Registration',
    },
    {
      title: 'Account Registration',
      key: 'accReg',
      icon: 'fe fe-briefcase',
      children: [
        // {
        //   title: 'Account Access Group',
        //   key: 'accRegAccountAccessGroup',
        //   url: '/registration/account/account-access-group',
        //   create_url: '/registration/account/account-access-group',
        // },
        {
          title: 'Account Groups',
          key: 'accRegAccountAccessGroupList',
          url: '/registration/account/account-access-group-list',
          create_url: '/registration/account/account-access-group',
        },
        // {
        //   title: 'Account',
        //   key: 'accRegAccount',
        //   url: '/registration/account/account',
        // },
        {
          title: 'Accounts',
          key: 'accRegAccountList',
          url: '/registration/account/account-list',
          create_url: '/registration/account/account',
        },
        // {
        //   title: 'Bank Account',
        //   key: 'accRegBankAccount',
        //   url: '/registration/account/bank-account',
        // },
        {
          title: 'Bank Accounts',
          key: 'accRegBankAccountList',
          url: '/registration/account/bank-account-list',
          create_url: '/registration/account/bank-account',
        },
        // {
        //   title: 'Fixed Asset',
        //   key: 'accRegFixedAsset',
        //   url: '/registration/account/fixed-asset',
        // },
        {
          title: 'Fixed Assets',
          key: 'accRegFixedAssetList',
          url: '/registration/account/fixed-asset-list',
          create_url: '/registration/account/fixed-asset',
        },
        // {
        //   title: 'Capital Account',
        //   key: 'accRegCapitalAccount',
        //   url: '/registration/account/capital',
        // },
        {
          title: 'Capital Accounts',
          key: 'accRegCapitalAccountList',
          url: '/registration/account/capital-list',
          create_url: '/registration/account/capital',
        },
      ],
    },
    {
      title: 'Chart of Accounts (COA)',
      key: 'coa',
      icon: 'fe fe-book-open',
      children: [
        {
          title: 'Chart of Accounts',
          key: 'coaChartOfAccounts',
          url: '/registration/coa/chart-of-accounts',
        },
        // {
        //   title: 'COA Group',
        //   key: 'coaCoaGroup',
        //   url: '/registration/coa/group',
        // },
        {
          title: 'COA Groups',
          key: 'coaCoaGroupList',
          url: '/registration/coa/group-list',
          create_url: '/registration/coa/group',
        },
        // {
        //   title: 'COA Subgroup',
        //   key: 'coaCoaSubgroup',
        //   url: '/registration/coa/subgroup',
        // },
        {
          title: 'COA Subgroups',
          key: 'coaCoaSubgroupList',
          url: '/registration/coa/subgroup-list',
          create_url: '/registration/coa/subgroup',
        },
      ],
    },
    {
      title: 'Party Registration',
      key: 'partyReg',
      icon: 'fe fe-hard-drive',
      children: [
        // {
        //   title: 'Region',
        //   key: 'partyRegRegion',
        //   url: '/registration/party/region',
        // },
        {
          title: 'Regions',
          key: 'partyRegRegionList',
          url: '/registration/party/region-list',
          create_url: '/registration/party/region',
        },
        // {
        //   title: 'Area',
        //   key: 'partyRegArea',
        //   url: '/registration/party/area',
        // },
        {
          title: 'Areas',
          key: 'partyRegAreaList',
          url: '/registration/party/area-list',
          create_url: '/registration/party/area',
        },
        // {
        //   title: 'Sector',
        //   key: 'partyRegSector',
        //   url: '/registration/party/sector',
        // },
        {
          title: 'Sectors',
          key: 'partyRegSectorList',
          url: '/registration/party/sector-list',
          create_url: '/registration/party/sector',
        },
        // {
        //   title: 'Client',
        //   key: 'partyRegClient',
        //   url: '/registration/party/client',
        // },
        {
          title: 'Clients',
          key: 'partyRegClientList',
          url: '/registration/party/client-list',
          create_url: '/registration/party/client',
        },
        // {
        //   title: 'Supplier',
        //   key: 'partyRegSupplier',
        //   url: '/registration/party/supplier',
        // },
        {
          title: 'Suppliers',
          key: 'partyRegSupplierList',
          url: '/registration/party/supplier-list',
          create_url: '/registration/party/supplier',
        },
      ],
    },
    {
      title: 'Product Registration',
      key: 'productReg',
      icon: 'fe fe-package',
      children: [
        // {
        //   title: 'Main Unit',
        //   key: 'productRegMainUnit',
        //   url: '/registration/product/main-unit',
        // },
        {
          title: 'Main Units',
          key: 'productRegMainUnitList',
          url: '/registration/product/main-unit-list',
          create_url: '/registration/product/main-unit',
        },
        // {
        //   title: 'Unit',
        //   key: 'productRegUnit',
        //   url: '/registration/product/unit',
        // },
        {
          title: 'Units',
          key: 'productRegUnitList',
          url: '/registration/product/unit-list',
          create_url: '/registration/product/unit',
        },
        // {
        //   title: 'Product Group',
        //   key: 'productRegProductGroup',
        //   url: '/registration/product/group',
        // },
        {
          title: 'Product Groups',
          key: 'productRegProductGroupList',
          url: '/registration/product/group-list',
          create_url: '/registration/product/group',
        },
        // {
        //   title: 'Product Category',
        //   key: 'productRegProductCategory',
        //   url: '/registration/product/category',
        // },
        {
          title: 'Product Categories',
          key: 'productRegProductCategoryList',
          url: '/registration/product/category-list',
          create_url: '/registration/product/category',
        },
        // {
        //   title: 'Product Brand',
        //   key: 'productRegProductBrand',
        //   url: '/registration/product/brand',
        // },
        {
          title: 'Product Brands',
          key: 'productRegProductBrandList',
          url: '/registration/product/brand-list',
          create_url: '/registration/product/brand',
        },
        // {
        //   title: 'Product Model',
        //   key: 'productRegProductModel',
        //   url: '/registration/product/model',
        // },
        {
          title: 'Product Models',
          key: 'productRegProductModelList',
          url: '/registration/product/model-list',
          create_url: '/registration/product/model',
        },
        // {
        //   title: 'Product',
        //   key: 'productRegProduct',
        //   url: '/registration/product/product',
        // },
        {
          title: 'Products',
          key: 'productRegProductList',
          url: '/registration/product/product-list',
          create_url: '/registration/product/product',
        },
        // {
        //   title: 'Package Group',
        //   key: 'productRegPackageGroup',
        //   url: '/registration/product/package-group',
        // },
        {
          title: 'Package Groups',
          key: 'productRegPackageGroupList',
          url: '/registration/product/package-group-list',
          create_url: '/registration/product/package-group',
        },
        // {
        //   title: 'Package',
        //   key: 'productRegPackage',
        //   url: '/registration/product/package',
        // },
        {
          title: 'Packages',
          key: 'productRegPackageList',
          url: '/registration/product/package-list',
          create_url: '/registration/product/package',
        },
      ],
    },
    {
      title: 'General Registration',
      key: 'generalReg',
      icon: 'fe fe-box',
      children: [
        // {
        //   title: 'Warehouse',
        //   key: 'generalRegWarehouse',
        //   url: '/registration/general/warehouse',
        // },
        {
          title: 'Warehouses',
          key: 'generalRegWarehouseList',
          url: '/registration/general/warehouse-list',
          create_url: '/registration/general/warehouse',
        },
        // {
        //   title: 'Service',
        //   key: 'generalRegService',
        //   url: '/registration/general/service',
        // },
        {
          title: 'Services',
          key: 'generalRegServiceList',
          url: '/registration/general/service-list',
          create_url: '/registration/general/service',
        },
      ],
    },
    {
      title: 'Human Resources',
      key: 'humanRes',
      icon: 'fe fe-user',
      children: [
        // {
        //   title: 'User',
        //   key: 'humanResUser',
        //   url: '/registration/human-resources/user',
        // },
        {
          title: 'Users',
          key: 'humanResUserList',
          url: '/registration/human-resources/user-list',
          create_url: '/registration/human-resources/user',
        },
        // {
        //   title: 'Salary User',
        //   key: 'humanResSalaryUser',
        //   url: '/registration/human-resources/salary-user',
        // },
        {
          title: 'Salary Users',
          key: 'humanResSalaryUserList',
          url: '/registration/human-resources/salary-user-list',
          create_url: '/registration/human-resources/salary-user',
        },
      ],
    },

    {
      category: true,
      title: 'Preferences',
    },
    {
      title: 'System',
      key: 'sys',
      icon: 'fe fe-settings',
      children: [
        {
          title: 'System Users',
          key: 'sysUserList',
          url: '/system/user-list',
        },
        {
          title: 'Shareholders',
          key: 'sysShareholderList',
          url: '/system/shareholder-list',
        },
      ],
    },

    {
      category: true,
      title: 'Receipts & Payments',
    },
    {
      title: 'Cash Vouchers',
      key: 'cashVouchers',
      icon: 'fe fe-dollar-sign',
      children: [
        // {
        //   title: 'Cash Receipt Voucher',
        //   key: 'cashReceiptVoucher',
        //   url: '/vouchers/cash-receipt-voucher',
        // },
        {
          title: 'Cash Receipt Vouchers',
          key: 'cashReceiptVoucherList',
          url: '/vouchers/cash-receipt-voucher-list',
          create_url: '/vouchers/cash-receipt-voucher',
        },
        // {
        //   title: 'Cash Payment Voucher',
        //   key: 'cashPaymentVoucher',
        //   url: '/vouchers/cash-payment-voucher',
        // },
        {
          title: 'Cash Payment Vouchers',
          key: 'cashPaymentVoucherList',
          url: '/vouchers/cash-payment-voucher-list',
          create_url: '/vouchers/cash-payment-voucher',
        },
      ],
    },
    {
      title: 'Bank Vouchers',
      key: 'bankVouchers',
      icon: 'fe fe-credit-card',
      children: [
        // {
        //   title: 'Bank Receipt Voucher',
        //   key: 'bankReceiptVoucher',
        //   url: '/vouchers/bank-receipt-voucher',
        // },
        {
          title: 'Bank Receipt Vouchers',
          key: 'bankReceiptVoucherList',
          url: '/vouchers/bank-receipt-voucher-list',
          create_url: '/vouchers/bank-receipt-voucher',
        },
        // {
        //   title: 'Bank Payment Voucher',
        //   key: 'bankPaymentVoucher',
        //   url: '/vouchers/bank-payment-voucher',
        // },
        {
          title: 'Bank Payment Vouchers',
          key: 'bankPaymentVoucherList',
          url: '/vouchers/bank-payment-voucher-list',
          create_url: '/vouchers/bank-payment-voucher',
        },
      ],
    },
    {
      title: 'Journal Vouchers',
      key: 'vouchers',
      icon: 'fe fe-shuffle',
      children: [
        // {
        //   title: 'Journal Voucher (JV)',
        //   key: 'jv',
        //   url: '/vouchers/jv',
        // },
        {
          title: 'Journal Vouchers (JV)',
          key: 'jvList',
          url: '/vouchers/jv-list',
          create_url: '/vouchers/jv',
        },
      ],
    },
    {
      title: 'Salary Vouchers',
      key: 'salaryVouchers',
      icon: 'fe fe-user',
      children: [
        // {
        //   title: 'Salary Slip',
        //   key: 'salarySlip',
        //   url: '/vouchers/salary-slip',
        // },
        {
          title: 'Salary Slips',
          key: 'salarySlipList',
          url: '/vouchers/salary-slip-list',
          create_url: '/vouchers/salary-slip',
        },
      ],
    },

    {
      category: true,
      title: 'Invoices',
    },
    {
      title: 'Purchases',
      key: 'purchases',
      icon: 'fe fe-shopping-cart',
      children: [
        // {
        //   title: 'Purchase Invoice',
        //   key: 'purchaseInvoice',
        //   url: '/invoices/purchase-invoice',
        // },
        {
          title: 'Purchase Invoices',
          key: 'purchaseInvoiceList',
          url: '/invoices/purchase-invoice-list',
          create_url: '/invoices/purchase-invoice',
        },
        // {
        //   title: 'Purchase Return Invoice',
        //   key: 'purchaseReturnInvoice',
        //   url: '/invoices/purchase-return-invoice',
        // },
        {
          title: 'Purchase Return Invoices',
          key: 'purchaseReturnInvoiceList',
          url: '/invoices/purchase-return-invoice-list',
          create_url: '/invoices/purchase-return-invoice',
        },
      ],
    },
    {
      title: 'Sales',
      key: 'sales',
      icon: 'fe fe-shopping-cart',
      children: [
        // {
        //   title: 'Sale Invoice',
        //   key: 'saleInvoice',
        //   url: '/invoices/sale-invoice',
        // },
        {
          title: 'Sale Invoices',
          key: 'saleInvoiceList',
          url: '/invoices/sale-invoice-list',
          create_url: '/invoices/sale-invoice',
        },
        // {
        //   title: 'Sale Return Invoice',
        //   key: 'saleReturnInvoice',
        //   url: '/invoices/sale-return-invoice',
        // },
        {
          title: 'Sale Return Invoices',
          key: 'saleReturnInvoiceList',
          url: '/invoices/sale-return-invoice-list',
          create_url: '/invoices/sale-return-invoice',
        },
      ],
    },
  ]
}
