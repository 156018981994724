const actions = {
  SET_STATE: 'service/SET_STATE',

  ADD: 'service/ADD',
  UPDATE: 'service/UPDATE',
  DELETE: 'service/DELETE',

  GET_LIST: 'service/GET_LIST',
  RESET_LIST: 'service/RESET_LIST',

  GET_EDITABLE: 'service/GET_EDITABLE',
  RESET_EDITABLE: 'service/RESET_EDITABLE',

  GET_DETAILS: 'service/GET_DETAILS',
  RESET_DETAILS: 'service/RESET_DETAILS',

  GET_LOOKUPS: 'service/GET_LOOKUPS',

  RESET_SAVING_STATUS: 'service/RESET_SAVING_STATUS',
}

export default actions
