const actions = {
  SET_STATE: 'coa/SET_STATE',

  GET_LIST: 'coa/GET_LIST',
  RESET_LIST: 'coa/RESET_LIST',

  GET_NOA_LOOKUPS: 'coa/GET_NOA_LOOKUPS',
}

export default actions
