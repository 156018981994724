const actions = {
  SET_STATE: 'salarySlip/SET_STATE',

  ADD: 'salarySlip/ADD',
  // UPDATE: 'salarySlip/UPDATE',
  // DELETE: 'salarySlip/DELETE',

  GET_LIST: 'salarySlip/GET_LIST',
  RESET_LIST: 'salarySlip/RESET_LIST',

  GET_EMPLOYEE_LIST: 'salarySlip/GET_EMPLOYEE_LIST',
  RESET_EMPLOYEE_LIST: 'salarySlip/RESET_EMPLOYEE_LIST',

  // GET_EDITABLE: 'salarySlip/GET_EDITABLE',
  // RESET_EDITABLE: 'salarySlip/RESET_EDITABLE',

  GET_DETAILS: 'salarySlip/GET_DETAILS',
  RESET_DETAILS: 'salarySlip/RESET_DETAILS',

  MARK_AS_PAID: 'salarySlip/MARK_AS_PAID',
  MARK_AS_PAID_BATCH: 'salarySlip/MARK_AS_PAID_BATCH',

  // ADD_NEW_LOOKUP: 'salarySlip/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'salarySlip/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'salarySlip/GET_LOOKUPS',
  // RESET_LOOKUPS: 'salarySlip/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'salarySlip/RESET_SAVING_STATUS',
}

export default actions
