const actions = {
  SET_STATE: 'productGroup/SET_STATE',

  ADD: 'productGroup/ADD',
  UPDATE: 'productGroup/UPDATE',
  DELETE: 'productGroup/DELETE',

  GET_LIST: 'productGroup/GET_LIST',
  RESET_LIST: 'productGroup/RESET_LIST',

  GET_EDITABLE: 'productGroup/GET_EDITABLE',
  RESET_EDITABLE: 'productGroup/RESET_EDITABLE',

  GET_DETAILS: 'productGroup/GET_DETAILS',
  RESET_DETAILS: 'productGroup/RESET_DETAILS',

  GET_LOOKUPS: 'productGroup/GET_LOOKUPS',
  RESET_LOOKUPS: 'productGroup/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'productGroup/RESET_SAVING_STATUS',
}

export default actions
