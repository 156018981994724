import { all } from 'redux-saga/effects'

import depreciationMethod from './depreciation-method/sagas'
import depreciationPeriod from './depreciation-period/sagas'
import bloodGroup from './blood-group/sagas'
import revenueDistribution from './revenue-distribution/sagas'
import salaryPeriod from './salary-period/sagas'
import userRole from './user-role/sagas'
import userType from './user-type/sagas'

export default function* rootSaga() {
  yield all([
    depreciationMethod(),
    depreciationPeriod(),
    bloodGroup(),
    revenueDistribution(),
    salaryPeriod(),
    userRole(),
    userType(),
  ])
}
