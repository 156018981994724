import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import authActions from 'redux/auth/actions'
import styles from './style.module.scss'

const mapStateToProps = ({ auth }) => ({ auth })

const ProfileMenu = ({ dispatch, auth }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: authActions.LOGOUT,
    })
  }

  const menu = (
    <Menu selectable={false}>
      <div className="px-1 pt-1 mx-2">
        <strong>
          <div>{auth.name}</div>
        </strong>
      </div>
      <div className="px-1 mx-2 text-uppercase font-size-12">
        <span>
          EMP-Code: <strong>{auth.employee_code || '-'}</strong>
        </span>
      </div>
      <Menu.Divider />
      <div className="p-1 mx-2">
        <div>
          <strong>
            <span>Role: </span>
          </strong>
          {auth.role?.title || '—'}
        </div>
        <div>
          <strong>
            <span>User Type: </span>
          </strong>
          {auth.type?.title || '—'}
        </div>
        <div>
          <strong>
            <span>Designation: </span>
          </strong>
          {auth.designation || '—'}
        </div>
      </div>
      <Menu.Divider />
      <div className="p-1 mx-2">
        <div>
          <strong>
            <span>Email: </span>
          </strong>
          {auth.email || '—'}
          <br />
          <strong>
            <span>Cell: </span>
          </strong>
          {auth.cell_number || '—'}
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/user/profile">
          <i className="fe fe-user mr-2" />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <span>Logout</span>
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} shape="square" size="large" src={auth.avatar} />
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
