import actions from './actions'

const initialState = {
  list: [],
  isLoadingList: false,
  totalRecords: 0,
  // editable: {},
  // isLoadingEditable: false,
  details: {},
  isLoadingDetails: false,
  // newLookup: {},
  // lookups: [],
  // isLoadingLookups: false,
  savingStatus: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
