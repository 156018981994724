const actions = {
  SET_STATE: 'auth/SET_STATE',
  LOGIN: 'auth/LOGIN',
  AUTH_ME: 'auth/AUTH_ME',
  LOGOUT: 'auth/LOGOUT',
  CHANGE_AVATAR: 'auth/CHANGE_AVATAR',
  UPDATE_PROFILE: 'auth/UPDATE_PROFILE',
  CHANGE_PASSWORD: 'auth/CHANGE_PASSWORD',
  REGISTER: 'auth/REGISTER',
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  VALIDATE_RESET_PASSWORD_TOKEN: 'auth/VALIDATE_RESET_PASSWORD_TOKEN',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  VALIDATE_WELCOME_EMAIL_TOKEN: 'auth/VALIDATE_WELCOME_EMAIL_TOKEN',
  SETUP_PASSWORD: 'auth/SETUP_PASSWORD',
}

export default actions
