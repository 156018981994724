import { all } from 'redux-saga/effects'

import region from './region/sagas'
import area from './area/sagas'
import sector from './sector/sagas'
import client from './client/sagas'
import supplier from './supplier/sagas'

export default function* rootSaga() {
  yield all([region(), area(), sector(), client(), supplier()])
}
