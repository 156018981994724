import { combineReducers } from 'redux'

import jv from './jv/reducers'
import crv from './cash-vouchers/receipt-voucher/reducers'
import cpv from './cash-vouchers/payment-voucher/reducers'
import brv from './bank-vouchers/receipt-voucher/reducers'
import bpv from './bank-vouchers/payment-voucher/reducers'
import salarySlip from './salary-vouchers/slip/reducers'

export default combineReducers({
  jv,
  crv,
  cpv,
  brv,
  bpv,
  salarySlip,
})
