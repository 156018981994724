import { combineReducers } from 'redux'

import region from './region/reducers'
import area from './area/reducers'
import sector from './sector/reducers'
import client from './client/reducers'
import supplier from './supplier/reducers'

export default combineReducers({
  region,
  area,
  sector,
  client,
  supplier,
})
