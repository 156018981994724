import { combineReducers } from 'redux'

import account from './account/reducers'
import coa from './coa/reducers'
import party from './party/reducers'
import product from './product/reducers'
import general from './general/reducers'
import others from './others/reducers'
import humanResources from './human-resources/reducers'

export default combineReducers({
  account,
  coa,
  party,
  product,
  general,
  others,
  humanResources,
})
