const actions = {
  SET_STATE: 'productBrand/SET_STATE',

  ADD: 'productBrand/ADD',
  UPDATE: 'productBrand/UPDATE',
  DELETE: 'productBrand/DELETE',

  GET_LIST: 'productBrand/GET_LIST',
  RESET_LIST: 'productBrand/RESET_LIST',

  GET_EDITABLE: 'productBrand/GET_EDITABLE',
  RESET_EDITABLE: 'productBrand/RESET_EDITABLE',

  GET_DETAILS: 'productBrand/GET_DETAILS',
  RESET_DETAILS: 'productBrand/RESET_DETAILS',

  GET_LOOKUPS: 'productBrand/GET_LOOKUPS',
  RESET_LOOKUPS: 'productBrand/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'productBrand/RESET_SAVING_STATUS',
}

export default actions
