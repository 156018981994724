import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/others/blood_groups'

export function* GET_LOOKUPS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      lookups: [],
      isLoadingLookups: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lookups: response.data.groups,
        isLoadingLookups: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingLookups: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_LOOKUPS, GET_LOOKUPS)])
}
