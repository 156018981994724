const actions = {
  SET_STATE: 'product/SET_STATE',

  ADD: 'product/ADD',
  UPDATE: 'product/UPDATE',
  DELETE: 'product/DELETE',

  GET_LIST: 'product/GET_LIST',
  RESET_LIST: 'product/RESET_LIST',

  GET_EDITABLE: 'product/GET_EDITABLE',
  RESET_EDITABLE: 'product/RESET_EDITABLE',

  GET_DETAILS: 'product/GET_DETAILS',
  RESET_DETAILS: 'product/RESET_DETAILS',

  ADD_NEW_LOOKUP: 'product/ADD_NEW_LOOKUP',
  RESET_NEW_LOOKUP: 'product/RESET_NEW_LOOKUP',

  GET_LOOKUPS: 'product/GET_LOOKUPS',
  RESET_LOOKUPS: 'product/RESET_LOOKUPS',

  GET_KEY_LOOKUPS: 'product/GET_KEY_LOOKUPS',
  GET_VALUE_LOOKUPS: 'product/GET_VALUE_LOOKUPS',

  RESET_SAVING_STATUS: 'product/RESET_SAVING_STATUS',
}

export default actions
