import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/chart_of_accounts'

export function* GET_LIST() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.chart_of_accounts,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
    },
  })
}

export function* GET_NOA_LOOKUPS({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      noaLookups: [],
      isLoadingNoaLookups: true,
    },
  })
  const response = yield call(httpService.getLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        noaLookups: response.data.chart_of_accounts,
        isLoadingNoaLookups: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingNoaLookups: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.GET_NOA_LOOKUPS, GET_NOA_LOOKUPS),
  ])
}
