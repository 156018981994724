const actions = {
  SET_STATE: 'mainUnit/SET_STATE',

  ADD: 'mainUnit/ADD',
  UPDATE: 'mainUnit/UPDATE',
  DELETE: 'mainUnit/DELETE',

  GET_LIST: 'mainUnit/GET_LIST',
  RESET_LIST: 'mainUnit/RESET_LIST',

  GET_EDITABLE: 'mainUnit/GET_EDITABLE',
  RESET_EDITABLE: 'mainUnit/RESET_EDITABLE',

  GET_DETAILS: 'mainUnit/GET_DETAILS',
  RESET_DETAILS: 'mainUnit/RESET_DETAILS',

  ADD_NEW_LOOKUP: 'mainUnit/ADD_NEW_LOOKUP',
  RESET_NEW_LOOKUP: 'mainUnit/RESET_NEW_LOOKUP',

  GET_LOOKUPS: 'mainUnit/GET_LOOKUPS',

  RESET_SAVING_STATUS: 'mainUnit/RESET_SAVING_STATUS',
}

export default actions
