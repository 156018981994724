import store from 'store'
import { all, call, put } from 'redux-saga/effects'
import * as httpService from 'services/http'
import actions from './actions'

export function* GET_CURRENT_SESSION_INFO() {
  const xAuthToken = store.get('accessToken')
  if (!xAuthToken) {
    return false
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingCurrentSessionInfo: true,
    },
  })
  const response = yield call(httpService.getItem, '/session/info')
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        currentSessionInfo: response.data.current_session_info,
        isLoadingCurrentSessionInfo: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingCurrentSessionInfo: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    GET_CURRENT_SESSION_INFO(), // run once on app load to fetch current session
  ])
}
