const actions = {
  SET_STATE: 'package/SET_STATE',

  ADD: 'package/ADD',
  UPDATE: 'package/UPDATE',
  DELETE: 'package/DELETE',

  GET_LIST: 'package/GET_LIST',
  RESET_LIST: 'package/RESET_LIST',

  GET_EDITABLE: 'package/GET_EDITABLE',
  RESET_EDITABLE: 'package/RESET_EDITABLE',

  GET_DETAILS: 'package/GET_DETAILS',
  RESET_DETAILS: 'package/RESET_DETAILS',

  GET_LOOKUPS: 'package/GET_LOOKUPS',

  RESET_SAVING_STATUS: 'package/RESET_SAVING_STATUS',
}

export default actions
