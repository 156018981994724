import { all } from 'redux-saga/effects'

import jv from './jv/sagas'
import crv from './cash-vouchers/receipt-voucher/sagas'
import cpv from './cash-vouchers/payment-voucher/sagas'
import brv from './bank-vouchers/receipt-voucher/sagas'
import bpv from './bank-vouchers/payment-voucher/sagas'
import salarySlip from './salary-vouchers/slip/sagas'

export default function* rootSaga() {
  yield all([jv(), crv(), cpv(), brv(), bpv(), salarySlip()])
}
