const actions = {
  SET_STATE: 'user/SET_STATE',

  ADD: 'user/ADD',
  UPDATE: 'user/UPDATE',
  DELETE: 'user/DELETE',
  DISCONTINUE: 'user/DISCONTINUE',
  CHANGE_DROPDOWN_STATUS: 'user/CHANGE_DROPDOWN_STATUS',
  CHANGE_LOGIN_STATUS: 'user/CHANGE_LOGIN_STATUS',

  GET_LIST: 'user/GET_LIST',
  RESET_LIST: 'user/RESET_LIST',

  GET_EDITABLE: 'user/GET_EDITABLE',
  RESET_EDITABLE: 'user/RESET_EDITABLE',

  GET_DETAILS: 'user/GET_DETAILS',
  RESET_DETAILS: 'user/RESET_DETAILS',

  GET_LOOKUPS: 'user/GET_LOOKUPS',

  RESET_SAVING_STATUS: 'user/RESET_SAVING_STATUS',
  RESET_PATCHING_STATUS: 'user/RESET_PATCHING_STATUS',
}

export default actions
