import React from 'react'
import { Spinner } from 'reactstrap'

const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="vh-100 row align-items-center">
        <Spinner color="primary" />
      </div>
    </div>
  )
}

export default LoadingSpinner
