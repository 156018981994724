import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import LoadingSpinner from 'components/custom/Spinners/LoadingSpinner'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ auth }) => ({ auth })
let previousPath = ''

const Layout = ({ auth, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = auth.authorized
  const isUserLoading = auth.loading
  const isAuthLayout = getLayout() === 'auth'

  const BootstrappedLayout = () => {
    // console.info(
    //   `UserLoading: ${isUserLoading}, --- UserAuthriz: ${isUserAuthorized}, --- AuthLayout: ${isAuthLayout}, --- PathName: ${pathname.toLowerCase()}`,
    // )
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      // console.info('******* LoadingSpinner *******')
      return <LoadingSpinner />
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isUserAuthorized && !isAuthLayout) {
      // console.info('******* Redirect to Login *******')
      return <Redirect to="/auth/login" />
    }
    if (
      isUserAuthorized &&
      (pathname.toLowerCase() === '/auth/login' ||
        pathname.toLowerCase() === '/auth/forgot-password' ||
        pathname.toLowerCase() === '/auth/reset-password' ||
        pathname.toLowerCase() === '/auth/setup-password')
    ) {
      // console.info('******* Already authorized! Redirect to Dashboard *******')
      return <Redirect to="/dashboard/alpha" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Xufire Profit | %s" title="Powered By Xufire" />

      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
