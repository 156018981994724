const actions = {
  SET_STATE: 'productSubGroup/SET_STATE',

  ADD: 'productSubGroup/ADD',
  UPDATE: 'productSubGroup/UPDATE',
  DELETE: 'productSubGroup/DELETE',

  GET_LIST: 'productSubGroup/GET_LIST',
  RESET_LIST: 'productSubGroup/RESET_LIST',

  GET_EDITABLE: 'productSubGroup/GET_EDITABLE',
  RESET_EDITABLE: 'productSubGroup/RESET_EDITABLE',

  GET_DETAILS: 'productSubGroup/GET_DETAILS',
  RESET_DETAILS: 'productSubGroup/RESET_DETAILS',

  GET_LOOKUPS: 'productSubGroup/GET_LOOKUPS',
  RESET_LOOKUPS: 'productSubGroup/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'productSubGroup/RESET_SAVING_STATUS',
}

export default actions
