import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import { PAYMENT_STATUS, PAYMENT_STATUS_OPTIONS } from 'services/shared/xufire-profit-utils'
import actions from './actions'

const endpoint = '/vouchers/salary_slip'

export function* ADD({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, endpoint, item)
  if (response) {
    notification.success({
      message: 'Slips Posted',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* MARK_AS_PAID({ payload }) {
  const { slip_id } = payload
  const { list } = yield select(state => state.vouchers.salarySlip)
  yield put({
    type: actions.SET_STATE,
    payload: {
      markAsPaidStatus: 'saving',
    },
  })
  const newList = list.map(item => {
    if (item.id === slip_id) {
      return {
        ...item,
        payment_status_id: PAYMENT_STATUS.PAID,
        payment_status: PAYMENT_STATUS_OPTIONS[1].label,
      }
    }
    return item
  })
  const response = yield call(httpService.addItem, `${endpoint}/mark_as_paid`, payload)
  if (response) {
    notification.success({
      message: 'Posted',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        markAsPaidStatus: 'success',
        list: newList,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        markAsPaidStatus: '', // failed
      },
    })
  }
}

export function* MARK_AS_PAID_BATCH({ payload }) {
  const { slip_ids } = payload
  const { list } = yield select(state => state.vouchers.salarySlip)
  yield put({
    type: actions.SET_STATE,
    payload: {
      markAsPaidStatus: 'saving',
    },
  })
  const newList = list.map(item => {
    if (slip_ids.includes(item.id)) {
      return {
        ...item,
        payment_status_id: PAYMENT_STATUS.PAID,
        payment_status: PAYMENT_STATUS_OPTIONS[1].label,
      }
    }
    return item
  })
  const response = yield call(httpService.addItem, `${endpoint}/mark_as_paid_batch`, payload)
  if (response) {
    notification.success({
      message: 'Posted',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        markAsPaidStatus: 'success',
        list: newList,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        markAsPaidStatus: '', // failed
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.slip_list,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* GET_EMPLOYEE_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingEmployeeList: true,
    },
  })
  const response = yield call(httpService.getItem, `${endpoint}/salary_slip_employee`, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        employeeList: response.data.employees,
        isLoadingEmployeeList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingEmployeeList: false,
      },
    })
  }
}

export function* RESET_EMPLOYEE_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      employeeList: [],
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingDetails: true,
    },
  })
  const response = yield call(httpService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        details: response.data.slip,
        isLoadingDetails: false,
      },
    })
  } else if (response && response.code === 404) {
    const { list, totalRecords } = yield select(state => state.vouchers.salarySlip)
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: list.filter(item => item.id !== id),
        totalRecords: Math.max(0, totalRecords - 1),
        isLoadingDetails: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingDetails: false,
      },
    })
  }
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.GET_EMPLOYEE_LIST, GET_EMPLOYEE_LIST),
    takeLatest(actions.RESET_EMPLOYEE_LIST, RESET_EMPLOYEE_LIST),
    takeLatest(actions.GET_DETAILS, GET_DETAILS),
    takeLatest(actions.MARK_AS_PAID, MARK_AS_PAID),
    takeLatest(actions.MARK_AS_PAID_BATCH, MARK_AS_PAID_BATCH),
    takeLatest(actions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(actions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
