import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as httpService from 'services/http'
import actions from './actions'

const endpoint = '/regions'

export function* ADD({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, endpoint, item)
  if (response) {
    notification.success({
      message: 'Region Added',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.updateItem, endpoint, id, item)
  if (response) {
    notification.success({
      message: 'Region Updated',
      description: 'Status: Successful',
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  const { list, totalRecords } = yield select(state => state.registration.party.region)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: list.filter(item => item.id !== id),
    },
  })
  const response = yield call(httpService.deleteItem, endpoint, id)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        totalRecords: Math.max(0, totalRecords - 1),
      },
    })
    notification.success({
      message: 'Region Removed',
      description: 'Status: Successful',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list,
      },
    })
  }
}

export function* GET_LIST({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingList: true,
    },
  })
  const response = yield call(httpService.getItem, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: response.data.regions,
        totalRecords: response.data.total_records,
        isLoadingList: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingList: false,
      },
    })
  }
}

export function* RESET_LIST() {
  // yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      list: [],
      totalRecords: 0,
    },
  })
}

export function* GET_EDITABLE({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingEditable: true,
    },
  })
  const response = yield call(httpService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        editable: response.data.region,
        isLoadingEditable: false,
      },
    })
  } else if (response && response.code === 404) {
    const { list, totalRecords } = yield select(state => state.registration.party.region)
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: list.filter(item => item.id !== id),
        totalRecords: Math.max(0, totalRecords - 1),
        isLoadingEditable: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingEditable: false,
      },
    })
  }
}

export function* RESET_EDITABLE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      editable: {},
    },
  })
}

export function* GET_DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLoadingDetails: true,
    },
  })
  const response = yield call(httpService.getItemById, endpoint, id)
  if (response && response.code === 200) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        details: response.data.region,
        isLoadingDetails: false,
      },
    })
  } else if (response && response.code === 404) {
    const { list, totalRecords } = yield select(state => state.registration.party.region)
    yield put({
      type: actions.SET_STATE,
      payload: {
        list: list.filter(item => item.id !== id),
        totalRecords: Math.max(0, totalRecords - 1),
        isLoadingDetails: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingDetails: false,
      },
    })
  }
}

export function* RESET_DETAILS() {
  yield delay(200)
  yield put({
    type: actions.SET_STATE,
    payload: {
      details: {},
    },
  })
}

export function* ADD_NEW_LOOKUP({ payload }) {
  const { item } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: 'saving',
    },
  })
  const response = yield call(httpService.addItem, `${endpoint}/lookup`, item)
  if (response) {
    notification.success({
      message: 'Region Added',
      description: 'Status: Successful',
    })
    const { lookups } = yield select(state => state.registration.party.region)
    yield put({
      type: actions.SET_STATE,
      payload: {
        newLookup: response.data.region,
        lookups: [...lookups, response.data.region],
        savingStatus: 'success',
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        savingStatus: '', // failed
      },
    })
  }
}

export function* RESET_NEW_LOOKUP() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      newLookup: {},
      savingStatus: '',
    },
  })
}

export function* GET_LOOKUPS({ payload }) {
  const queryParams = { ...payload }
  yield put({
    type: actions.SET_STATE,
    payload: {
      lookups: [],
      isLoadingLookups: true,
    },
  })
  const response = yield call(httpService.getLookup, endpoint, queryParams)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        lookups: response.data.regions,
        isLoadingLookups: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        isLoadingLookups: false,
      },
    })
  }
}

export function* RESET_LOOKUPS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      lookups: [],
    },
  })
}

export function* RESET_SAVING_STATUS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingStatus: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeLatest(actions.GET_LIST, GET_LIST),
    takeLatest(actions.RESET_LIST, RESET_LIST),
    takeLatest(actions.GET_EDITABLE, GET_EDITABLE),
    takeLatest(actions.RESET_EDITABLE, RESET_EDITABLE),
    takeLatest(actions.GET_DETAILS, GET_DETAILS),
    takeLatest(actions.RESET_DETAILS, RESET_DETAILS),
    takeLatest(actions.ADD_NEW_LOOKUP, ADD_NEW_LOOKUP),
    takeLatest(actions.RESET_NEW_LOOKUP, RESET_NEW_LOOKUP),
    takeLatest(actions.GET_LOOKUPS, GET_LOOKUPS),
    takeLatest(actions.RESET_LOOKUPS, RESET_LOOKUPS),
    takeLatest(actions.RESET_SAVING_STATUS, RESET_SAVING_STATUS),
  ])
}
