const actions = {
  SET_STATE: 'coaSubgroup/SET_STATE',

  ADD: 'coaSubgroup/ADD',
  UPDATE: 'coaSubgroup/UPDATE',
  DELETE: 'coaSubgroup/DELETE',

  GET_LIST: 'coaSubgroup/GET_LIST',
  RESET_LIST: 'coaSubgroup/RESET_LIST',

  GET_EDITABLE: 'coaSubgroup/GET_EDITABLE',
  RESET_EDITABLE: 'coaSubgroup/RESET_EDITABLE',

  GET_DETAILS: 'coaSubgroup/GET_DETAILS',
  RESET_DETAILS: 'coaSubgroup/RESET_DETAILS',

  ADD_NEW_LOOKUP: 'coaSubgroup/ADD_NEW_LOOKUP',
  RESET_NEW_LOOKUP: 'coaSubgroup/RESET_NEW_LOOKUP',

  GET_LOOKUPS: 'coaSubgroup/GET_LOOKUPS',
  RESET_LOOKUPS: 'coaSubgroup/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'coaSubgroup/RESET_SAVING_STATUS',
}

export default actions
