import apiClient from 'services/axios'

export async function addItem(endpoint, item) {
  return apiClient
    .post(endpoint, item)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function updateItem(endpoint, id, item) {
  return apiClient
    .put(`${endpoint}/${id}`, item)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function patchItem(endpoint, id, item) {
  return apiClient
    .patch(`${endpoint}/${id}`, item)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function deleteItem(endpoint, id) {
  return apiClient
    .delete(`${endpoint}/${id}`)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function getItem(endpoint, queryParams) {
  return apiClient
    .get(endpoint, { params: queryParams })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function getItemById(endpoint, id) {
  return apiClient
    .get(`${endpoint}/${id}`)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        return error.response.data
      }
      // Something happened in setting up the request that triggered an Error
      console.log('Error:', error.message)
      return false
    })
}

export async function getLookup(endpoint, queryParams) {
  return apiClient
    .get(`${endpoint}/lookup`, { params: queryParams })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}

export async function addFile(endpoint, formData, config, params) {
  return apiClient
    .post(endpoint, formData, {
      params,
      headers: {
        'content-type': 'multipart/form-data',
      },
      ...config,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(() => false)
}
