const actions = {
  SET_STATE: 'shareholder/SET_STATE',

  ADD: 'shareholder/ADD',
  UPDATE: 'shareholder/UPDATE',
  DELETE: 'shareholder/DELETE',

  GET_LIST: 'shareholder/GET_LIST',
  RESET_LIST: 'shareholder/RESET_LIST',

  GET_EDITABLE: 'shareholder/GET_EDITABLE',
  RESET_EDITABLE: 'shareholder/RESET_EDITABLE',

  GET_DETAILS: 'shareholder/GET_DETAILS',
  RESET_DETAILS: 'shareholder/RESET_DETAILS',

  ADD_NEW_LOOKUP: 'shareholder/ADD_NEW_LOOKUP',
  RESET_NEW_LOOKUP: 'shareholder/RESET_NEW_LOOKUP',

  GET_LOOKUPS: 'shareholder/GET_LOOKUPS',
  RESET_LOOKUPS: 'shareholder/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'shareholder/RESET_SAVING_STATUS',
}

export default actions
