import apiClient from 'services/axios'
import store from 'store'

export async function login(email, password) {
  return apiClient
    .post('/auth/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data.user
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      // if (error.response) {
      //   // Request made and server responded
      //   console.log(error.response.status, error.response.data)
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log('Error:', error.message)
      // }
      return false
    })
}

export async function authMe() {
  const xAuthToken = store.get('accessToken')
  if (!xAuthToken) {
    return false
  }

  return apiClient
    .get('/auth/me')
    .then(response => {
      if (response) {
        const { accessToken } = response.data.me
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(() => {
      return false
    })
}

export async function logout() {
  store.remove('accessToken')
  return true
  // return apiClient
  //   .get('/auth/logout')
  //   .then(() => {
  //   })
  //   .catch(err => console.log(err))
}

// export async function register(email, password, name) {
//   return apiClient
//     .post('/auth/register', {
//       email,
//       password,
//       name,
//     })
//     .then(response => {
//       if (response) {
//         const { accessToken } = response.data.user
//         if (accessToken) {
//           store.set('accessToken', accessToken)
//         }
//         return response.data
//       }
//       return false
//     })
//     .catch(err => console.log(err))
// }

export async function forgotPassword(email) {
  return apiClient
    .post('/auth/forgot_password', {
      email,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateProfile(info) {
  return apiClient
    .post('/auth/update_profile', info)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function changePassword(passes) {
  return apiClient
    .post('/auth/change_password', passes)
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function validateResetPasswordToken(token) {
  return apiClient
    .post('/auth/validate_reset_password_token', {
      token,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(token, new_password) {
  return apiClient
    .post('/auth/reset_password', {
      token,
      new_password,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function validateWelcomeEmailToken(token) {
  return apiClient
    .post('/auth/validate_welcome_email_token', {
      token,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function setupPassword(token, new_password) {
  return apiClient
    .post('/auth/setup_password', {
      token,
      new_password,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}
