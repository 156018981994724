const actions = {
  SET_STATE: 'bpv/SET_STATE',

  ADD: 'bpv/ADD',
  // UPDATE: 'bpv/UPDATE',
  // DELETE: 'bpv/DELETE',

  GET_LIST: 'bpv/GET_LIST',
  RESET_LIST: 'bpv/RESET_LIST',

  // GET_EDITABLE: 'bpv/GET_EDITABLE',
  // RESET_EDITABLE: 'bpv/RESET_EDITABLE',

  GET_DETAILS: 'bpv/GET_DETAILS',
  RESET_DETAILS: 'bpv/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'bpv/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'bpv/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'bpv/GET_LOOKUPS',
  // RESET_LOOKUPS: 'bpv/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'bpv/RESET_SAVING_STATUS',
}

export default actions
