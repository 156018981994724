import React from 'react'
import { Spinner } from 'reactstrap'

const FallbackSpinner = () => {
  return (
    <div className="d-flex justify-content-center my-5">
      <Spinner color="primary" />
    </div>
  )
}

export default FallbackSpinner
