const actions = {
  SET_STATE: 'brv/SET_STATE',

  ADD: 'brv/ADD',
  // UPDATE: 'brv/UPDATE',
  // DELETE: 'brv/DELETE',

  GET_LIST: 'brv/GET_LIST',
  RESET_LIST: 'brv/RESET_LIST',

  // GET_EDITABLE: 'brv/GET_EDITABLE',
  // RESET_EDITABLE: 'brv/RESET_EDITABLE',

  GET_DETAILS: 'brv/GET_DETAILS',
  RESET_DETAILS: 'brv/RESET_DETAILS',

  // ADD_NEW_LOOKUP: 'brv/ADD_NEW_LOOKUP',
  // RESET_NEW_LOOKUP: 'brv/RESET_NEW_LOOKUP',

  // GET_LOOKUPS: 'brv/GET_LOOKUPS',
  // RESET_LOOKUPS: 'brv/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'brv/RESET_SAVING_STATUS',
}

export default actions
