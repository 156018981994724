const actions = {
  SET_STATE: 'account/SET_STATE',

  ADD: 'account/ADD',
  UPDATE: 'account/UPDATE',
  DELETE: 'account/DELETE',

  GET_LIST: 'account/GET_LIST',
  RESET_LIST: 'account/RESET_LIST',

  GET_LEDGER_LIST: 'account/GET_LEDGER_LIST',
  RESET_LEDGER_LIST: 'account/RESET_LEDGER_LIST',

  GET_EDITABLE: 'account/GET_EDITABLE',
  RESET_EDITABLE: 'account/RESET_EDITABLE',

  GET_DETAILS: 'account/GET_DETAILS',
  RESET_DETAILS: 'account/RESET_DETAILS',

  GET_LOOKUPS: 'account/GET_LOOKUPS',
  RESET_LOOKUPS: 'account/RESET_LOOKUPS',

  RESET_SAVING_STATUS: 'account/RESET_SAVING_STATUS',
}

export default actions
