import { combineReducers } from 'redux'

import chartOfAccounts from './chart-of-accounts/reducers'
import coaGroup from './coa-group/reducers'
import coaSubgroup from './coa-subgroup/reducers'

export default combineReducers({
  chartOfAccounts,
  coaGroup,
  coaSubgroup,
})
