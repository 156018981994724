import { all } from 'redux-saga/effects'

import pi from './purchase-invoices/purchase-invoice/sagas'
import pri from './purchase-invoices/purchase-return-invoice/sagas'
import si from './sale-invoices/sale-invoice/sagas'
import sri from './sale-invoices/sale-return-invoice/sagas'

export default function* rootSaga() {
  yield all([pi(), pri(), si(), sri()])
}
