import React from 'react'
import { BackTop } from 'antd'
import style from './style.module.scss'

const BackToTop = () => {
  return (
    <BackTop>
      <i className={`${style.backToTopInner} fa fa-angle-double-up`} />
    </BackTop>
  )
}

export default BackToTop
